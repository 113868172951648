import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/vbr3',
    name: 'VALKYRIA BATTLE ROYALE 3',
    component: () => import( '../views/ContestSplash.vue')
  },
  {
    path: '/vbr3/signup',
    name: 'Signup',
    component: () => import( '../views/ContestSignup.vue')
  },
  {
    path: '/vbr3/submit',
    name: 'Submit',
    component: () => import( '../views/ContestRoundSubmit.vue')
  },
  {
    path: '/vbr3/signup/success',
    name: 'Signup Successful',
    component: () => import( '../views/SignupSuccessful.vue')
  },
  {
    path: '/vbr3/signup/already-registered',
    name: 'Already Registered',
    component: () => import( '../views/AlreadySignedUp.vue')
  },
  {
    path: '/verified',
    name: 'Verified',
    component: () => import('../views/Verified.vue'),
  },
  {
    path: '*',
    redirect: '/vbr3'
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
