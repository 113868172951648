import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import VueAxios from 'vue-axios';
import axios from 'axios';

import './style.scss';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileDownload} from '@fortawesome/free-solid-svg-icons';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);


library.add(faFileDownload);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');